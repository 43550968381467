<script setup lang="ts">
const { t } = useT();
const { open } = useNlcModals();
const { data: appInitData } = useAppInitData();
const { firstItemData, firstItemTimer } = useScretchCardData({
	immediate: !appInitData.value?.isGuest && appInitData.value?.magicBox?.isActive
});

const buttonName = computed(() => {
	if (firstItemData.value?.progress?.points === firstItemData.value?.progress?.pointsComplete) {
		return t("cosmicBoxes.rewards.button.name1");
	}

	if (firstItemData.value?.timer) {
		return firstItemTimer.value;
	}

	return t("cosmicBoxes.rewards.button.name2");
});

const handleClick = () => {
	dispatchGAEvent({
		event: "click_button",
		button_name: "cosmic_boxes"
	});
	if (firstItemData.value?.progress?.points === firstItemData.value?.progress?.pointsComplete) {
		open("LazyOModalCosmicBoxesSelection");
		return;
	}

	if (firstItemData.value?.timer) {
		navigateTo("/cosmic-boxes", { external: true });
		return;
	}

	window?.$cash?.$router?.push?.("/cash/deposit-by-money/");
};
</script>

<template>
	<div @click="handleClick">
		<div class="icon">
			<NuxtImg
				src="/nuxt-img/cosmic-boxes/step-2.png"
				width="84"
				height="84"
				format="avif"
				loading="lazy"
				alt="cosmic boxes"
			/>
		</div>
		<div class="content">
			<AText class="color-neutral-100" :size="16" :modifiers="['bold', 'uppercase']" as="div">
				{{ t("cosmicBoxes.rewards.title") }}
			</AText>

			<MCosmicBoxesProgress
				class="progress-bar"
				:value="firstItemData.progress?.pointsComplete"
				:maxValue="firstItemData.progress?.points"
			/>

			<AText class="progress-text color-neutral-60" :size="10" as="div">
				{{
					firstItemData?.progress?.points === firstItemData?.progress?.pointsComplete
						? t("cosmicBoxes.rewards.subtitle2")
						: t("cosmicBoxes.rewards.subtitle")
				}}
			</AText>
		</div>
		<AButton variant="primary" class="btn" size="s" :modifiers="[firstItemData?.timer ? 'disabled' : '']">
			<AText :size="12" :modifiers="['uppercase']">{{ buttonName }}</AText>
		</AButton>
	</div>
</template>
<style lang="scss" scoped>
.progress-bar {
	margin-top: 6px;
	max-width: 180px;

	&:deep(.box) {
		height: 22px;

		.text-value {
			font-size: 10px;
			line-height: 14px;
		}
	}

	&:deep(.nuxt-icon) {
		top: calc(50% - 12px);

		svg {
			font-size: 24px;
		}
	}
}
.icon {
	display: flex;
	align-items: center;

	img {
		width: 52px;
		height: 52px;
	}
}
</style>
